import {
  useFetchOneServiceCard,
  useSubmitRequestContent,
  useSubmitReturnDateHistory,
  useSubmitStatuses,
} from "@bms/hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { notification } from "antd";
import { openNotification } from "@bms/components";
import { RangePickerProps } from "antd/es/date-picker";

export type ReceivedProps = Record<string, any>;

export const STATUS = {
  resolved: 1,
  not_compatible: 2,
};

const useRequestContent = (props: ReceivedProps) => {
  const { id } = useParams();

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openSetDate, setOpenSetDate] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isDatePicker, setIsDatePicker] = useState<boolean>(false);
  const [date, setDate] = useState<any>(null);
  const [status, setStatus] = useState<number>(STATUS["not_compatible"]);

  const { data, isSuccess, isLoading, isFetching } = useFetchOneServiceCard(id);
  const { mutate: requestContentAction } = useSubmitRequestContent();
  const { mutate: returnDateHistoryAction } = useSubmitReturnDateHistory();
  const { mutate: statusesAction } = useSubmitStatuses();
  const [isUpdate, setIsUpdate] = useState<boolean>(true);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const onSubmitConfirm = async (status: string) => {
    statusesAction(
      {
        payload: {
          confirm_payment_status:
            data?.status?.confirm_payment_status || "not_payment",
          invoice_status: data?.status?.invoice_status || "unclaimed",
          working_status: status,
          service_card_id: data?.id,
          has_notification: true,
        },
        statusId: data?.status?.id,
      },
      {
        onSuccess() {
          if (isUpdate) {
            openNotification();
          }

          setIsEnd(true);
          setIsDatePicker(false);
          setDate(null);
          setOpenConfirmDialog(false);
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );

    requestContentAction(
      {
        payload: {
          is_mobile: true,
          return_order_date: null,
          service_card_id: data?.id,
          status: status === "resolved" ? "finished" : "in_progress",
          has_notification: true,
        },
        requestContentId: data?.request_content?.id,
      },
      {
        onSuccess({ data }) {
          setOpenSetDate(false);
          setDate(data.return_order_date);
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  const onSubmit = async () => {
    returnDateHistoryAction(
      {
        payload: {
          is_mobile: true,
          request_content_id: data?.request_content?.id,
          return_order_date: dayjs(date).toISOString(),
          start_time: dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"),
          end_time: dayjs(date).add(1, "hours").format("YYYY-MM-DDTHH:mm:ssZ"),
        },
        returnDateHistoryId: null,
      },
      {
        onError(error: any) {
          setIsUpdate(false);
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );

    statusesAction(
      {
        payload: {
          confirm_payment_status:
            data?.status?.confirm_payment_status || "not_payment",
          invoice_status: data?.status?.invoice_status || "unclaimed",
          working_status: "not_compatible",
          service_card_id: data?.id,
          has_notification: true,
        },
        statusId: data?.status?.id,
      },
      {
        onSuccess() {
          setIsEnd(true);
          setDate(null);
          setOpenConfirmDialog(false);
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );

    requestContentAction(
      {
        payload: {
          is_mobile: true,
          return_order_date: null,
          service_card_id: data?.id,
          status: "finished",
          has_notification: true,
        },
        requestContentId: data?.request_content?.id,
      },
      {
        onSuccess({ data }) {
          if (isUpdate) {
            openNotification();
          }
          setOpenSetDate(false);
          setIsDatePicker(false);
          setIsEnd(false);
          setDate(data.return_order_date);
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess) {
      const { request_content, status } = data || {};
      setStatus(
        status?.working_status === "resolved"
          ? STATUS["resolved"]
          : STATUS["not_compatible"]
      );
      if (status?.working_status === "resolved") {
        setIsEnd(true);
      } else {
        setIsEnd(false);
      }
      if (request_content?.return_order_date) {
        setDate(request_content?.return_order_date);
      } else {
        setDate(null);
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isSuccess, isLoading, isFetching]);

  return {
    ...props,
    isEnd,
    date,
    isDatePicker,
    openConfirmDialog,
    openSetDate,
    isLoading,
    isFetching,
    status,
    data,
    disabledDate,
    setIsEnd,
    onSubmit,
    onSubmitConfirm,
    setDate,
    setIsDatePicker,
    setOpenSetDate,
    setOpenConfirmDialog,
  };
};

export type Props = ReturnType<typeof useRequestContent>;

export default useRequestContent;
