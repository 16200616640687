import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  isEmpty,
  orderBy,
  pick,
  uniq,
  without,
  omit,
  max,
  isEqual,
  xorWith,
  get,
} from "lodash";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { notification } from "antd";

import {
  useDeleteServiceCard,
  useFetchOneServiceCard,
  useFetchPaymentCategories,
  useFetchReceptions,
  useFetchSubcontractors,
  useFetchWorkers,
  useSendMessage,
  useSubmitContractorInformation,
  useSubmitMemo,
  useSubmitOrderInformation,
  useSubmitPayment,
  useSubmitRequestContent,
  useSubmitStatuses,
  useSubmitWorkCost,
  useSubmitWorkReport,
} from "@bms/hooks";
import {
  Messages,
  PaymentCategories,
  Receptions,
  SelectOptionsType,
  ServiceCard,
  Subcontractors,
  Workers,
} from "@bms/types";
import { DATE_TIME_FORMAT, DEPARTMENT_IN_CHANGE } from "@bms/constants";
import { userSelector } from "@bms/features/auth/selector";
import { validation } from "./schema";
import moment from "moment";

export type ReceivedProps = Record<string, any>;

export const getValueTabs = (valueTab: string) => {
  return Array.from(Array(Number(valueTab)).keys()).length;
};

export const TAB_1 = [
  "working_status",
  "invoice_status",
  "confirm_payment_status",
  "paid_at",
];
export const TAB_2 = [
  "order_date",
  "support_type",
  "address_payment_type",
  "building_id",
  "resident_id",
  "resident_root_id",
  "move_in_date",
];
export const TAB_3 = [
  "department_in_charge",
  "reception_staff_id",
  "contact_person",
  "contract_reception_id",
];
export const TAB_4 = [
  "content",
  "start_working_hour",
  "end_working_hour",
  "return_order_date",
];
export const TAB_5 = ["work_report_attributes"];
export const TAB_7 = ["busines_trip_fee", "work_fee", "disposal_fee"];

type TNavigatePrompt = "require" | "no-require" | "auto";
const useServiceCardControl = (props: ReceivedProps) => {
  const { state } = useLocation();
  const { id: serviceCardId } = useParams();
  const isView = state?.view || false;
  const [navigatePrompt, setNavigatePrompt] = useState<TNavigatePrompt>(
    isView ? "no-require" : "require"
  );
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const id = serviceCardId === "create" ? null : serviceCardId;

  const [valueTab, setValueTab] = useState<string>(state?.tabKey || "1");
  const [currentTab, setCurrentTab] = useState<string[]>(["8"]);
  const [workerOptions, setWorkerOptions] = useState<
    {
      value: string;
      label: string;
      department_in_charge: DEPARTMENT_IN_CHANGE;
    }[]
  >([]);
  const [subcontractorOptions, setSubcontractorOptions] = useState<
    SelectOptionsType[]
  >([]);
  const [phoneOptions, setPhoneOptions] = useState<SelectOptionsType[]>([]);
  const [receptionOptions, setReceptionOptions] = useState<SelectOptionsType[]>(
    []
  );
  const [paymentCategoryData, setPaymentCategoryOptions] = useState<
    SelectOptionsType[]
  >([]);
  const [messages, setMessages] = useState<Messages[]>([]);
  const [isNotification, setIsNotification] = useState<boolean>(false);
  const [filterKey, setFilterKey] = useState<string | null>("resident_id");
  const [requestOrderDate, setRequestOrderDate] = useState<string>("");
  const [requestStartDate, setRequestStartDate] = useState<string>("");
  const [requestEndDate, setRequestEndDate] = useState<string>("");

  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    reset,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validation()),
  });
  const {
    mutate: statusesAction,
    isLoading: isStatusesSubmitting,
    isSuccess: isMutateStatuses,
  } = useSubmitStatuses(true);
  const {
    mutate: orderInfoAction,
    isLoading: isOrderSubmitting,
    // isSuccess: isMutateOrderInfo,
  } = useSubmitOrderInformation(true);
  const {
    mutate: contractorInfoAction,
    isLoading: isContractSubmitting,
    // isSuccess: isMutateContractorInfo,
  } = useSubmitContractorInformation(true);
  const {
    mutate: workReportAction,
    isLoading: isWorkReportSubmitting,
    // isSuccess: isMutateWorkReport,
  } = useSubmitWorkReport(true);
  const {
    mutate: requestContentAction,
    isLoading: isRequestContentSubmitting,
    // isSuccess: isMutateRequestContent,
  } = useSubmitRequestContent(true);
  const {
    mutate: workCostsAction,
    isLoading: isWorkCostSubmitting,
    // isSuccess: isMutateWorkCost,
  } = useSubmitWorkCost(true);
  const {
    mutate: paymentAction,
    isLoading: isPaymentSubmitting,
    // isSuccess: isMutatePayment,
  } = useSubmitPayment(false);
  const {
    mutate: sendMessage,
    isLoading: isMessageSubmitting,
    // isSuccess: isMutateSendMessage,
  } = useSendMessage(true);
  const {
    mutate: memoAction,
    isLoading: isMemoSubmitting,
    // isSuccess: isMutateMemo,
  } = useSubmitMemo();
  const {
    data: serviceCardData,
    isSuccess,
    // isLoading: isServiceCardLoading,
    isFetching: isServiceCardFetching,
  } = useFetchOneServiceCard(id);
  const {
    data: receptionsData,
    isSuccess: isReceptionSuccess,
    isLoading: isReceptionLoading,
  } = useFetchReceptions(1, 100000);
  const {
    data: workersData,
    isSuccess: isWorkerSuccess,
    isLoading: isWorkerLoading,
  } = useFetchWorkers(1, 100000, {
    is_tab: valueTab,
    by_order_date: requestOrderDate ? requestOrderDate : undefined,
    filter_by_worker: true,
    request_content_id: serviceCardData?.request_content?.id,
    start_working_hour: serviceCardData?.request_content?.start_time
      ? serviceCardData?.request_content?.start_time
      : requestOrderDate && requestStartDate
      ? new Date(dayjs(requestOrderDate + " " + requestStartDate).toISOString())
      : "",
    end_working_hour: serviceCardData?.request_content?.end_time
      ? serviceCardData?.request_content?.end_time
      : requestOrderDate && requestEndDate
      ? new Date(dayjs(requestOrderDate + " " + requestEndDate).toISOString())
      : "",
  });
  const { mutate: mutateDelete } = useDeleteServiceCard();
  const { data: workersDataOriginal, isSuccess: isWorkerOriginalSuccess } =
    useFetchWorkers(1, 100000, {
      filter_by_worker: true,
    });

  const {
    data: workersOfficeDataOriginal,
    isSuccess: isWorkerOfficeOriginalSuccess,
  } = useFetchWorkers(1, 100000, {
    filter_by_office_worker: true,
    filter_by_worker: true,
  });

  const {
    data: subcontractorsData,
    isSuccess: isSubcontractorsSuccess,
    isLoading: isSubcontractorLoading,
  } = useFetchSubcontractors(1, 100000);
  const {
    data: paymentCategoriesData,
    isSuccess: isCategorySuccess,
    isLoading: isCategoryLoading,
  } = useFetchPaymentCategories(1, 100000);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    const values = getValues();
    const data = serviceCardData as ServiceCard;
    if (isSuccess) {
      const {
        order_information,
        contractor_information,
        request_content,
        work_reports,
        work_cost,
        payment,
        id,
        memo,
      } = data;

      if (newValue === "2" && order_information?.has_notification) {
        orderInfoAction({
          payload: {
            has_notification: false,
            id: serviceCardData?.order_information?.id,
            service_card_id: serviceCardId,
          },
          orderInfoId: serviceCardData?.order_information?.id,
        });
      }

      if (newValue === "3" && contractor_information?.has_notification) {
        contractorInfoAction({
          payload: {
            has_notification: false,
            id: serviceCardData?.contractor_information?.id,
            service_card_id: serviceCardId,
          },
          contractorId: serviceCardData?.contractor_information?.id,
        });
      }

      if (newValue === "4" && request_content?.has_notification) {
        requestContentAction({
          payload: {
            has_notification: false,
            id: serviceCardData?.request_content?.id,
            order_date: values?.request_order_date
              ? moment(values.request_order_date).format(
                  DATE_TIME_FORMAT.PAYLOAD
                )
              : null,
            service_card_id: serviceCardId,
          },
          requestContentId: serviceCardData?.request_content?.id,
        });
      }

      if (
        newValue === "5" &&
        work_reports?.find((item) => item?.has_notification)
      ) {
        const formData = new FormData();
        const payload = [
          {
            service_card_id: serviceCardId,
            has_notification: false,
          },
        ];

        payload.forEach((item: any) => {
          Object.keys(item).forEach((key) => {
            formData.append(`work_report_attributes[]${key}`, item[key]);
          });
        });
        workReportAction({
          payload: formData,
          workReportId: !isEmpty(serviceCardData?.work_reports),
        });
      }

      if (newValue === "6" && payment?.has_notification) {
        paymentAction({
          payload: {
            has_notification: false,
            id: serviceCardData?.payment?.id,
            service_card_id: serviceCardId,
          },
          paymentId: serviceCardData?.payment?.id,
        });
      }

      if (newValue === "7" && work_cost?.has_notification) {
        workCostsAction({
          payload: {
            has_notification: false,
            id: serviceCardData?.work_cost?.id,
            service_card_id: serviceCardId,
          },
          workCostsId: serviceCardData?.work_cost?.id,
        });
      }

      if (newValue === "8" && memo?.has_notification) {
        memoAction({
          payload: {
            has_notification: false,
            id: memo?.id,
            service_card_id: id,
          },
          memoId: memo?.id,
        });
      }
    }

    setValueTab(newValue);
  };

  const isArrayEqual = (x: any, y: any) => isEmpty(xorWith(x, y, isEqual));

  const handleNotification = (record: any, currentTab: string) => {
    const currentData = serviceCardData as ServiceCard;
    const serviceCardId = id;

    // The first create, it will break without any notification
    if (!serviceCardId) {
      return false;
    }

    if (currentTab === "1") {
      const keys = [
        "confirm_payment_status",
        "invoice_status",
        "paid_at",
        "working_status",
      ];

      const originalObj = pick(currentData?.status, keys);
      const payloadObj = pick(record, keys);

      return !isEqual(payloadObj, originalObj);
    }

    if (currentTab === "2") {
      const keys =
        !record?.building_address &&
        !record?.building_management_number &&
        !record?.building_name &&
        !record?.building_tentative_name
          ? [
              "report_printing",
              "memo",
              "room_number",
              "order_date",
              "contact_person",
              "building_data_id",
              "resident_data_id",
              "address_payment_type",
              "support_type",
            ]
          : [
              "order_date",
              "order_contact_person",
              "memo",
              "support_type",
              "address_payment_type",
              "report_printing",
              "building_data_id",
              "resident_data_id",
              "building_name",
              "room_number",
              "resident_name",
              "resident_contact",
              "building_tentative_name",
              "move_in_date",
              "tenant_contact",
              "building_address",
              "building_management_number",
              "owner_name",
              "contractor_name",
              "contractor_data_id",
              "owner_manager_number",
              "contractor_address",
              "resident_address",
              "owner_address",
            ];

      const originalObj = {
        ...pick(currentData?.order_information, keys),
        ...pick(currentData?.order_information?.building_data, keys),
        resident_data_id:
          currentData?.order_information?.building_data?.resident_data_id?.toString(),
        building_data_id:
          currentData?.order_information?.building_data?.building_data_id?.toString(),
      };

      const payloadObj = {
        ...pick(record, keys),
        resident_data_id: record?.resident_data_id?.toString(),
        building_data_id: record?.building_data_id?.toString(),
        order_date: record?.order_date
          ? dayjs(record.order_date, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        support_type: record?.support_type || null,
        address_payment_type: record?.address_payment_type || null,
      };

      return !isEqual(payloadObj, originalObj);
    }

    if (currentTab === "3") {
      const keys = [
        "department_in_charge",
        "reception_staff_id",
        "contract_reception_id",
        "sub_department_in_charge",
        "employee_in_charge_id",
        "contact_person_employee_in_charge",
      ];

      const originalObj = {
        ...pick(currentData?.contractor_information, keys),
        contract_reception_id:
          currentData?.contractor_information?.reception?.id,
        employee_in_charge_id:
          currentData?.contractor_information?.employee_in_charge?.id,
        reception_staff_id:
          currentData?.contractor_information?.reception_staff?.id,
      };
      const payloadObj = {
        ...pick(record, keys),
        reception_staff_id: record?.reception_staff_id,
        contract_reception_id: record?.reception_id,
      };

      return !isEqual(payloadObj, originalObj);
    }

    if (currentTab === "4") {
      const keys = ["content", "contruction_subject"];

      const originalObj = {
        ...pick(currentData?.request_content, keys),
        end_time: currentData?.request_content?.end_time
          ? dayjs(currentData?.request_content.end_time).toISOString()
          : null,
        order_date: currentData?.request_content.order_date,
        return_order_date: currentData?.request_content.return_order_date,
        start_time: currentData?.request_content?.start_time
          ? dayjs(currentData?.request_content.start_time).toISOString()
          : null,
        subcontractor_ids: currentData?.request_content?.subcontractors
          .map((item) => item?.id)
          .join(""),
        worker_ids: currentData?.request_content?.workers
          .map((item) => item?.id)
          .join(""),
      };
      const payloadObj = {
        ...pick(record, keys),
        end_time: record?.end_time,
        order_date: record?.order_date,
        return_order_date: record?.return_order_date,
        start_time: record?.start_time,
        subcontractor_ids: record?.subcontractor_ids?.join(""),
        worker_ids: record?.worker_ids?.join(""),
      };

      return !isEqual(payloadObj, originalObj);
    }

    if (currentTab === "5") {
      const originalObj = currentData?.work_reports;
      const payloadObj = pick(record, [
        "work_report_attributes",
      ]).work_report_attributes?.map((item: any, index: number) => ({
        ...item,
        after_photo:
          item?.after_photo || currentData?.work_reports?.[index]?.after_photo,
        before_photo:
          item?.before_photo ||
          currentData?.work_reports?.[index]?.before_photo,
      }));

      return !serviceCardId
        ? !!Object.keys(payloadObj?.[0]).find(
            (item: any) => !isEmpty(get(payloadObj?.[0], item))
          )
        : !isArrayEqual(originalObj, payloadObj);
    }

    if (currentTab === "6") {
      const originalObj = {
        bill_id: currentData?.payment.bill?.id || "",
        claimant_classification_id:
          currentData?.payment?.claimant_classification?.id || "",
        cost_sharing_category_id:
          currentData?.payment?.cost_sharing_category?.id || "",
        expense_payment_category_id:
          currentData?.payment?.expense_payment_category?.id || "",
        person_in_charge_id: currentData?.payment?.person_in_charge?.id || "",
      };
      const payloadObj = {
        bill_id: record.bill_id || "",
        claimant_classification_id: record?.claimant_classification_id || "",
        cost_sharing_category_id: record?.cost_sharing_category_id || "",
        expense_payment_category_id: record?.expense_payment_category_id || "",
        person_in_charge_id: record?.person_in_charge_id || "",
      };

      return !isEqual(payloadObj, originalObj);
    }

    if (currentTab === "7") {
      const keys = [
        "busines_trip_fee",
        "work_fee",
        "disposal_fee",
        "total_material_cost",
        "total",
      ];

      const originalObj = pick(currentData?.work_cost, keys);
      const payloadObj = {
        busines_trip_fee: record?.busines_trip_fee || null,
        work_fee: record?.work_fee || null,
        disposal_fee: record?.disposal_fee || null,
        total_material_cost:
          currentData?.work_cost?.total_material_cost === 0
            ? 0
            : record?.total_material_cost || null,
        total: record?.total || null,
      };

      return !serviceCardId
        ? !!Object.keys(payloadObj).find(
            (item: any) => !isEmpty(get(payloadObj, item))
          )
        : !isEqual(payloadObj, originalObj) ||
            !isEqual(
              currentData?.work_cost.work_cost_items,
              record.work_cost_items
            );
    }

    if (currentTab === "8") {
      const result = !isEmpty(record?.message)
        ? record?.is_new_message
        : !isEmpty(record?.memo_images);

      return result;
    }

    return false;
  };

  const onSubmit = async () => {
    let successApiCount = 0;
    let allowNavigate = true;
    let isErrorTab3 = true;
    const values = getValues();
    const mergeValueTab = [
      ...(!isEmpty(currentTab) ? max(currentTab) || "8" : valueTab),
      valueTab,
    ];
    const tabConditional = getValueTabs(max(mergeValueTab) || "8");
    const attemptToNavigate = () => {
      successApiCount++;
      // có tổng cộng 8 api được gọi, nếu 8 api đều thành công thì mới navigate
      if (successApiCount === +tabConditional) {
        setNavigatePrompt("auto");
      }
    };

    if (!isEmpty(errors)) return;

    // Status
    if (tabConditional >= 1) {
      const payload = pick(values, [
        "confirm_payment_status",
        "invoice_status",
        "working_status",
        "paid_at",
      ]);
      statusesAction(
        {
          payload: {
            ...payload,
            has_notification: handleNotification(values, "1"),
            service_card_id: id,
          },
          statusId: serviceCardData?.status?.id,
        },
        {
          onSuccess({ data }) {
            // attemptToNavigate();
            const serviceCardId = data.service_card_id;

            // Order Information
            if (tabConditional >= 2) {
              const payload = {
                ...pick(values, [
                  "reception_id",
                  "resident_id",
                  "building_id",
                  "report_printing",
                  "memo",
                  "resident_contact",
                  // TODO
                  "building_name",
                  "room_number",
                  "resident_name",
                  "resident_contact",
                  "building_tentative_name",
                  "move_in_date",
                  "tenant_contact",
                  "building_address",
                  "building_management_number",
                  "owner_name",
                  "owner_id",
                  "contractor_name",
                  "contractor_data_id",
                  "owner_manager_number",
                  "contractor_address",
                  "resident_address",
                  "owner_address",
                  "field_key",
                ]),
                order_date: dayjs(values.order_date).format(
                  DATE_TIME_FORMAT.PAYLOAD
                ),
                contact_person: values?.order_contact_person,
                building_data_id: values?.building_id,
                resident_data_id: values?.resident_id,
                address_payment_type:
                  values?.address_payment_type?.length > 0
                    ? values?.address_payment_type?.[0]
                    : null,
                support_type:
                  values?.support_type?.length > 0
                    ? values?.support_type?.[0]
                    : null,
              };

              orderInfoAction(
                {
                  payload: {
                    ...payload,
                    has_notification: handleNotification(payload, "2"),
                    id: serviceCardData?.order_information?.id,
                    service_card_id: serviceCardId,
                  },
                  orderInfoId: serviceCardData?.order_information?.id,
                },
                {
                  onSuccess({ data }) {
                    setValue("report_printing", data?.report_printing);
                    trigger("report_printing");
                    attemptToNavigate();
                  },
                  onError(error: any) {
                    allowNavigate = false;
                    notification["error"]({
                      message:
                        { error }?.error?.response?.data?.error || "Failed",
                    });
                  },
                }
              );
            }

            // Contractor Information
            if (tabConditional >= 3) {
              const payload = {
                ...pick(values, [
                  "department_in_charge",
                  "reception_staff_id",
                  "contact_person",
                  "sub_department_in_charge",
                  "employee_in_charge_id",
                  "contact_person_employee_in_charge",
                ]),
                reception_id: values?.contract_reception_id,
                sub_department_in_charge:
                  values?.sub_department_in_charge === "third_houses"
                    ? "sub_third_houses"
                    : values?.sub_department_in_charge === "oyakudachiya"
                    ? "sub_oyakudachiya"
                    : values?.sub_department_in_charge === "consignment"
                    ? "sub_consignment"
                    : null,
              };

              contractorInfoAction(
                {
                  payload: {
                    ...payload,
                    has_notification: handleNotification(payload, "3"),
                    id: serviceCardData?.contractor_information?.id,
                    by_order_date: values?.request_order_date
                      ? dayjs(values.request_order_date).format("YYYY-MM-DD")
                      : null,
                    service_card_id: serviceCardId,
                  },
                  contractorId: serviceCardData?.contractor_information?.id,
                },
                {
                  onError(error: any) {
                    allowNavigate = false;
                    notification["error"]({
                      message:
                        { error }?.error?.response?.data?.error || "Failed",
                    });
                    isErrorTab3 = true;
                    !id &&
                      mutateDelete({
                        payload: {
                          service_card_attributes: [{ id: serviceCardId }],
                        },
                      });
                  },
                  onSuccess: () => {
                    isErrorTab3 = false;
                    attemptToNavigate();
                  },
                }
              );
            }

            // Request Content
            if (tabConditional >= 4) {
              const workerIds = Object.keys(values)
                .filter((item) => item.includes("worker_id_"))
                .map((obj) => values[obj]);

              const subcontractorIds = Object.keys(values)
                .filter((item) => item.includes("subcontractor_id_"))
                .map((obj) => values[obj]);

              const payload = {
                ...pick(values, ["content", "contruction_subject"]),
                start_time: values.start_working_hour
                  ? dayjs(
                      `${dayjs(values.request_order_date || dayjs()).format(
                        "YYYY-MM-DD"
                      )} ${values.start_working_hour}`
                    ).toISOString()
                  : null,
                end_time: values.start_working_hour
                  ? dayjs(
                      `${dayjs(values.request_order_date || dayjs()).format(
                        "YYYY-MM-DD"
                      )} ${values.end_working_hour}`
                    ).toISOString()
                  : null,
                order_date: values?.request_order_date
                  ? dayjs(values.request_order_date).format("YYYY-MM-DD")
                  : null,
                return_order_date: values?.return_order_date
                  ? dayjs(
                      `${dayjs(values.return_order_date).format(
                        "YYYY-MM-DD"
                      )} 23:59`
                    ).toISOString()
                  : null,
                worker_ids: uniq(without(workerIds, "")).filter((item) =>
                  (
                    workersData?.workers?.map((o: any) => o?.id?.toString()) ||
                    []
                  ).includes(item?.toString())
                ),
                subcontractor_ids: uniq(without(subcontractorIds, "")),
              };

              requestContentAction(
                {
                  payload: {
                    ...payload,
                    has_notification: handleNotification(payload, "4"),
                    id: serviceCardData?.request_content?.id,
                    service_card_id: serviceCardId,
                  },
                  requestContentId: serviceCardData?.request_content?.id,
                },
                {
                  onSuccess({ data }) {
                    attemptToNavigate();
                  },
                  onError(error: any) {
                    allowNavigate = false;
                    notification["error"]({
                      message:
                        { error }?.error?.response?.data?.error || "Failed",
                    });
                  },
                }
              );
            }

            // Work Reports
            if (tabConditional >= 5) {
              const isUpdate = !isEmpty(serviceCardData?.work_reports);
              const formData = new FormData();
              const payload: any = pick(values, ["work_report_attributes"]);
              // khi không xem tab Work Report, thì payload không có giá trị nào dẫn đến api bị lỗi, cách fix là thêm giá trị mặc định
              if (!payload.work_report_attributes.length)
                payload.work_report_attributes.push({ title: "", comment: "" });

              for (
                let index = 0;
                index < payload.work_report_attributes.length;
                index++
              ) {
                const element = payload.work_report_attributes?.[index];
                const elementOriginal = serviceCardData?.work_reports?.[index];
                if (elementOriginal?.after_photo && !element?.after_photo) {
                  element.delete_after_photo = true;
                }
                if (elementOriginal?.before_photo && !element?.before_photo) {
                  element.delete_before_photo = true;
                }
                if (element.after_photo?.image_path || !element.after_photo) {
                  delete element.after_photo;
                }
                if (element.before_photo?.image_path || !element.before_photo) {
                  delete element.before_photo;
                }
              }

              payload.work_report_attributes
                .map((obj: any) => ({
                  ...(serviceCardId && { service_card_id: serviceCardId }),
                  has_notification:
                    handleNotification(values, "5") || !!serviceCardId,
                  ...obj,
                }))
                .forEach((item: any) => {
                  Object.keys(item).forEach((key) => {
                    if (
                      key == "has_notification" &&
                      (item[key] == null || item[key] == undefined)
                    ) {
                      item[key] = false;
                    }
                    formData.append(
                      `work_report_attributes[]${key}`,
                      key === "before_photo"
                        ? item["before_photo_key"]
                        : key === "after_photo"
                        ? item["after_photo_key"]
                        : item[key]
                    );
                  });
                });

              formData.delete("work_report_attributes[]before_photo_key");
              formData.delete("work_report_attributes[]after_photo_key");

              workReportAction(
                {
                  payload: formData,
                  workReportId: isUpdate,
                },
                {
                  onSuccess({ data }) {
                    attemptToNavigate();
                  },
                  onError(error: any) {
                    allowNavigate = false;
                    notification["error"]({
                      message:
                        { error }?.error?.response?.data?.error || "Failed",
                    });
                  },
                }
              );
            }

            // Payment Information
            if (tabConditional >= 6) {
              const payload = pick(values, [
                "claimant_classification_id",
                "expense_payment_category_id",
                "cost_sharing_category_id",
                "bill_id",
                "person_in_charge_id",
              ]);

              paymentAction(
                {
                  payload: {
                    ...payload,
                    has_notification: handleNotification(values, "6"),
                    id: serviceCardData?.payment?.id,
                    service_card_id: serviceCardId,
                  },
                  paymentId: serviceCardData?.payment?.id,
                },
                {
                  onSuccess({ data }) {
                    attemptToNavigate();
                  },
                  onError(error: any) {
                    allowNavigate = false;
                    notification["error"]({
                      message:
                        { error }?.error?.response?.data?.error || "Failed",
                    });
                  },
                }
              );
            }

            // Work Cost
            if (tabConditional >= 7) {
              const payload = pick(values, [
                "busines_trip_fee",
                "work_fee",
                "disposal_fee",
                "total_material_cost",
                "total",
                "work_cost_items",
              ]);

              workCostsAction(
                {
                  payload: {
                    ...payload,
                    has_notification: handleNotification(values, "7"),
                    id: serviceCardData?.work_cost?.id,
                    service_card_id: serviceCardId,
                  },
                  workCostsId: serviceCardData?.work_cost?.id,
                },
                {
                  onSuccess({ data }) {
                    attemptToNavigate();
                  },
                  onError(error: any) {
                    allowNavigate = false;
                    notification["error"]({
                      message:
                        { error }?.error?.response?.data?.error || "Failed",
                    });
                  },
                }
              );
            }

            // Memo
            if (tabConditional === 8) {
              const payload = pick(values, ["message", "memo_images"]);
              const formData = new FormData();
              const parsePayload: any = {
                ...(serviceCardData?.memo?.id && {
                  memo_id: serviceCardData?.memo?.id,
                }),
                has_notification:
                  handleNotification(values, "8") === undefined
                    ? false
                    : handleNotification(values, "8"),
                worker_id: user?.worker?.id,
                body: payload?.message || "",
                service_card_id: id || serviceCardId,
              };

              Object.keys(parsePayload).forEach((item: any) => {
                formData.append(`${item}`, parsePayload?.[item]);
              });

              if (!isEmpty(values?.memo_images)) {
                (values?.memo_images as any).forEach((item: any) => {
                  formData.append(`images[]`, item?.image);
                });
              }
              sendMessage(
                {
                  payload: formData,
                },
                {
                  onSuccess: ({ data }) => {
                    if (allowNavigate && !isErrorTab3)
                      setNavigatePrompt("auto");

                    setIsNotification(true);
                    setMessages(
                      orderBy([...messages, data], (obj) => obj.id, "asc")
                    );
                    setValue("message", "");
                    setValue("memo_images", []);
                    if (data?.memo?.service_card_id && !serviceCardId) {
                      navigate(`${data?.memo?.service_card_id}`);
                    } else attemptToNavigate();
                  },
                  onError(error: any) {
                    allowNavigate = false;
                    notification["error"]({
                      message:
                        { error }?.error?.response?.data?.error || "Failed",
                    });
                  },
                }
              );
            }
          },
          onError(error: any) {
            allowNavigate = false;
            notification["error"]({
              message: { error }?.error?.response?.data?.error || "Failed",
            });
          },
        }
      );
    }
  };

  const isLoading = useMemo(() => {
    return (
      isServiceCardFetching ||
      isReceptionLoading ||
      // isWorkerLoading ||
      isSubcontractorLoading ||
      isCategoryLoading
    );
  }, [
    isCategoryLoading,
    isReceptionLoading,
    isServiceCardFetching,
    isSubcontractorLoading,
    // isWorkerLoading,
  ]);

  const isSubmitting = useMemo(() => {
    return (
      isStatusesSubmitting ||
      isOrderSubmitting ||
      isContractSubmitting ||
      isWorkReportSubmitting ||
      isRequestContentSubmitting ||
      isWorkCostSubmitting ||
      isMessageSubmitting ||
      isMemoSubmitting ||
      isPaymentSubmitting
    );
  }, [
    isContractSubmitting,
    isMemoSubmitting,
    isMessageSubmitting,
    isOrderSubmitting,
    isPaymentSubmitting,
    isRequestContentSubmitting,
    isStatusesSubmitting,
    isWorkCostSubmitting,
    isWorkReportSubmitting,
  ]);

  const handleSuccess = useCallback(() => {
    return isMutateStatuses;
  }, [isMutateStatuses]);

  const handleError = useCallback(
    (keys: string[]) =>
      Object.keys(errors).find((key: string) => keys.includes(key)),
    [errors]
  );

  useEffect(() => {
    if (isEmpty(errors)) {
      setCurrentTab(currentTab);
      return;
    }
    if (handleError(TAB_7)) {
      setValueTab("7");
      setCurrentTab(uniq([...currentTab, valueTab]));
    }
    if (handleError(TAB_5)) {
      setValueTab("5");
      setCurrentTab(uniq([...currentTab, valueTab]));
    }
    if (handleError(TAB_4)) {
      setValueTab("4");
      setCurrentTab(uniq([...currentTab, valueTab]));
    }
    if (handleError(TAB_3)) {
      setValueTab("3");
      setCurrentTab(uniq([...currentTab, valueTab]));
    }
    if (handleError(TAB_2)) {
      setValueTab("2");
      setCurrentTab(uniq([...currentTab, valueTab]));
    }
    if (handleError(TAB_1)) {
      setValueTab("1");
      setCurrentTab(uniq([...currentTab, valueTab]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useLayoutEffect(() => {
    // Handle Tab4
    if (isWorkerSuccess) {
      setWorkerOptions(
        (workersData.workers as Workers[]).map((item) => ({
          value: item.id.toString(),
          label:
            item.name.length > 20
              ? item.name.toString().substring(0, 20) + "..."
              : item.name,
          department_in_charge: item.department_in_charge,
        }))
      );

      setPhoneOptions(
        (workersData.workers as Workers[]).map((item) => ({
          value: item.phone_number,
          label: item.phone_number,
        }))
      );
    }
  }, [
    isWorkerSuccess && workersData && valueTab == "4",
    requestOrderDate,
    requestStartDate,
    requestEndDate,
  ]);

  useEffect(() => {
    if (isReceptionSuccess) {
      setReceptionOptions(
        (receptionsData.receptions as Receptions[]).map((item) => ({
          value: item.id.toString(),
          label: item.type_name,
        }))
      );
    }

    if (isSubcontractorsSuccess) {
      setSubcontractorOptions(
        (subcontractorsData.subcontractors as Subcontractors[]).map((item) => ({
          value: item.id.toString(),
          label: item.form_name,
        }))
      );
    }

    if (isCategorySuccess) {
      setPaymentCategoryOptions(
        (paymentCategoriesData.payment_categories as PaymentCategories[]).map(
          (item) => ({
            category: item.category,
            value: item.id.toString(),
            label: item.method,
          })
        )
      );
    }
  }, [
    isCategorySuccess,
    isReceptionSuccess,
    isSubcontractorsSuccess,
    paymentCategoriesData,
    receptionsData,
    subcontractorsData,
  ]);

  useEffect(() => {
    if (valueTab === "1" && serviceCardData?.status?.has_notification) {
      statusesAction({
        payload: {
          has_notification: false,
          service_card_id: serviceCardId,
        },
        statusId: serviceCardData?.status?.id,
      });
    }
  }, [
    serviceCardData?.status?.has_notification,
    serviceCardData?.status?.id,
    serviceCardId,
    statusesAction,
    valueTab,
  ]);

  useEffect(() => {
    const data = serviceCardData as ServiceCard;
    // Handle Tab3
    if (
      isSuccess &&
      data &&
      isWorkerOriginalSuccess &&
      isWorkerOfficeOriginalSuccess
    ) {
      const { contractor_information } = data;

      const contactPerson = workersDataOriginal?.workers?.find(
        (item: any) => item?.id === contractor_information?.reception_staff?.id
      );
      const contactPersonEmployeeInCharge =
        workersOfficeDataOriginal?.workers?.find(
          (item: any) =>
            item?.id === contractor_information?.employee_in_charge?.id
        );

      setValue(
        "request_order_date",
        getValues("request_order_date")
          ? dayjs(getValues("request_order_date"))
          : null
      );
      setValue("contact_person", contactPerson?.phone_number);
      setValue(
        "contact_person_employee_in_charge",
        contactPersonEmployeeInCharge?.phone_number
      );
    }
  }, [
    getValues,
    isSuccess,
    isWorkerOriginalSuccess,
    isWorkerOfficeOriginalSuccess,
    reset,
    serviceCardData,
    setValue,
    workersDataOriginal?.workers,
    workersOfficeDataOriginal?.workers,
  ]);

  useEffect(() => {
    const data = serviceCardData as ServiceCard;
    if (isSuccess && data) {
      const {
        status,
        order_information,
        contractor_information,
        request_content,
        work_reports,
        work_cost,
        payment,
        id,
      } = data;

      setFilterKey(order_information?.field_key || "resident_id");
      if (requestOrderDate !== request_content?.order_date) {
        setRequestOrderDate(request_content?.order_date);
      }

      reset({
        // Tab1: Status
        card_id: id,
        working_status: status?.working_status || "not_compatible",
        invoice_status: status?.invoice_status || "unclaimed",
        confirm_payment_status: status?.confirm_payment_status || "not_payment",
        paid_at: status?.paid_at ? dayjs(status?.paid_at) : null,

        // Tab2: Order Information
        ...order_information,
        ...(order_information?.building_data && !order_information?.building
          ? {
              ...pick(order_information?.building_data, [
                "resident_data_id",
                "building_data_id",
                "building_name",
                "room_number",
                "resident_name",
                "resident_contact",
                "building_tentative_name",
                "tenant_contact",
                "building_address",
                "building_town_name",
                "owner_name",
                "owner_id",
                "contractor_name",
                "contractor_data_id",
                "owner_manager_number",
                "contractor_address",
                "resident_address",
                "owner_address",
                "move_in_date",
                "building_management_number",
              ]),
              building_id: order_information?.building_data?.building_data_id,
              resident_id: order_information?.building_data?.resident_data_id,
            }
          : {
              ...pick(getValues(), [
                "building_name",
                "room_number",
                "resident_name",
                "resident_contact",
                "building_tentative_name",
                "move_in_date",
                "tenant_contact",
                "building_address",
                "building_town_name",
                "owner_name",
                "owner_id",
                "order_date",
                "contractor_name",
                "contractor_data_id",
                "owner_manager_number",
                "contractor_address",
                "resident_address",
                "owner_address",
                "field_key",
                "building_management_number",
              ]),
              building_id: order_information?.building?.building_id,
              resident_id:
                order_information?.building?.resident?.resident_data_id,
            }),
        report_printing: order_information?.report_printing,
        room_number: order_information?.building_data?.room_number,
        order_date: order_information?.order_date
          ? dayjs(order_information.order_date)
          : null,
        field_key: order_information?.field_key || "resident_id",
        reception_id: order_information?.reception?.id?.toString(),
        address_payment_type: isEmpty(order_information?.address_payment_type)
          ? undefined
          : [order_information?.address_payment_type],
        support_type: isEmpty(order_information?.support_type)
          ? undefined
          : [order_information?.support_type],
        order_contact_person: order_information?.contact_person,
        memo: order_information?.memo,

        // Tab3: Contractor Information
        ...contractor_information,
        department_in_charge: contractor_information?.department_in_charge,
        reception_staff_id: contractor_information?.reception_staff?.id,
        contract_reception_id: contractor_information?.reception?.id,
        employee_in_charge_id: contractor_information?.employee_in_charge?.id,
        sub_department_in_charge:
          contractor_information?.sub_department_in_charge == 0 ||
          contractor_information?.sub_department_in_charge == "sub_consignment"
            ? "consignment"
            : contractor_information?.sub_department_in_charge == 1 ||
              contractor_information?.sub_department_in_charge ==
                "sub_third_houses"
            ? "third_houses"
            : contractor_information?.sub_department_in_charge == 3 ||
              contractor_information?.sub_department_in_charge ==
                "sub_oyakudachiya"
            ? "oyakudachiya"
            : "",

        // Tab4: Request Content
        ...omit(request_content, ["order_date"]),
        start_working_hour: request_content?.start_time
          ? dayjs(request_content?.start_time).format("HH:mm")
          : null,
        end_working_hour: request_content?.end_time
          ? dayjs(request_content?.end_time).format("HH:mm")
          : null,
        ...request_content?.workers?.reduce(
          (curr, value, index) => ({
            ...curr,
            [`worker_id_${index}`]: value.id,
            [`department_in_charge_${index}`]: value.department_in_charge,
          }),
          {}
        ),
        ...request_content?.subcontractors?.reduce(
          (curr, value, index) => ({
            ...curr,
            [`subcontractor_id_${index}`]: value?.id,
          }),
          {}
        ),
        request_content_id: request_content?.id,
        request_order_date: request_content?.order_date
          ? dayjs(request_content?.order_date)
          : null,
        return_order_date: request_content?.return_order_date
          ? dayjs(request_content?.return_order_date)
          : null,

        // Tab5: Work Report
        work_report_attributes: orderBy(work_reports, (obj) => obj.id, "asc"),

        // Tab6: Payment Information
        claimant_classification_id: payment?.claimant_classification?.id,
        expense_payment_category_id: payment?.expense_payment_category?.id,
        cost_sharing_category_id: payment?.cost_sharing_category?.id,
        bill_id: payment?.bill?.id,
        person_in_charge_id: payment?.person_in_charge?.id,

        // Tab7: Work Cost
        ...work_cost,
      });
    } else {
      reset({
        working_status: "not_compatible",
        invoice_status: "unclaimed",
        confirm_payment_status: "not_payment",
        building_id: state?.building_id || "",
        resident_id: state?.resident_id || "",
        resident_root_id: state?.resident_root_id || "",
        work_report_attributes: [
          {
            title: "",
            before_photo: null,
            after_photo: null,
            comment: "",
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSuccess,
    isLoading,
    isServiceCardFetching,
    serviceCardData,
    reset,
    getValues,
  ]);

  return {
    ...props,
    workersData: workersData?.workers || [],
    isWorkerLoading,
    workersDataOriginal: workersDataOriginal?.workers || [],
    workersOfficeDataOriginal: workersOfficeDataOriginal?.workers || [],
    isRedirectFromBuilding: state?.flagRedirect || false,
    filterKey: state?.searchFilter || filterKey,
    id,
    isView,
    valueTab,
    control,
    isLoading,
    serviceCardData,
    workerOptions,
    phoneOptions,
    receptionOptions,
    subcontractorOptions,
    errors,
    messages,
    isSubmitting,
    paymentCategoryData,
    isNotification,
    multiFilterKey: true,
    navigatePrompt,
    handleError,
    setError,
    clearErrors,
    setRequestOrderDate,
    setRequestStartDate,
    setRequestEndDate,
    setFilterKey,
    handleSuccess,
    setMessages,
    watch,
    reset,
    setValue,
    getValues,
    register,
    handleChange,
    handleSubmit,
    onSubmit,
    trigger,
  };
};

export type Props = ReturnType<typeof useServiceCardControl>;

export default useServiceCardControl;
